
import {
  onMounted,
  onUnmounted,
  defineComponent,
  inject,
  reactive,
  ref,
  toRefs,
  watch
} from "vue";
import { PageParams } from "@/apis/interface/PageParams";
import { RespBody, RespBodyList } from "@/apis/interface/Resp";
import {
  ISysResource,
  SysResourceFilter,
  SysResourceModel,
  SysResourceNodeModel
} from "@/apis/interface/ISysResource";
import bus from "@/utils/bus";
import { ElMessage } from "element-plus";
import { IOther } from "@/apis/interface/IOther";
import { setNewOrders, putOrders } from "@/utils/tools";

export default defineComponent({
  name: "List",
  props: {
    terminal: {
      type: String,
      default: "applet"
    }
  },
  setup(props) {
    const { terminal } = toRefs(props);
    const apiSysResource = inject("apiSysResource") as ISysResource;
    const apiOther = inject("apiOther") as IOther;

    const pageParams: PageParams = reactive({
      aaIndex: 1,
      aaSize: 50
    });

    const filter: SysResourceFilter = reactive({
      terminal,
      parentId: null,
      roleIds: []
    });

    let defaultProps = {
      children: "children",
      label: "name"
    };

    let filterText = ref("");
    let leftTree = ref(null);

    onUnmounted(() => {
      bus.off("on-table-row-edit-ok");
    });

    let levelDataList = ref<SysResourceModel[]>([]);

    let leftTreeData = ref<SysResourceNodeModel[]>([]);

    let loadTreeData = async () => {
      leftTreeData.value = [];
      const resp = (await apiSysResource.getMenuTree(
        terminal.value
      )) as RespBodyList<SysResourceNodeModel>;
      console.log(">>", resp.object);
      leftTreeData.value = resp.object;
    };

    let loadLevelData = async (parentId: number) => {
      const resp = await apiSysResource.getList(terminal.value, parentId);
      const data = resp as RespBodyList<SysResourceModel>;
      levelDataList.value = data.object;
      console.log("==>", levelDataList);
    };

    let activeTabName = ref("attr");

    let currentNodeModel = ref<SysResourceModel | null>(null);

    let sysResourceView = ref();

    let onNodeClick = async (model: SysResourceModel) => {
      currentNodeModel.value = model;
      if (model.id) {
        levelDataList.value = [];
        activeTabName.value = "attr";
        await loadLevelData(model.id);
        (sysResourceView.value as any).setData(ref([]), currentNodeModel);
      }
    };

    let clickEditCurrent = () => {
      console.log("编辑");
      bus.emit("on-table-row-edit", {
        dataList: [],
        dataRow: currentNodeModel.value
      });
    };

    let clickCreate = () => {
      bus.emit("on-table-row-edit", {
        dataList: [],
        dataRow: new SysResourceNodeModel({
          terminal: "applet",
          parentId: currentNodeModel.value?.id || 0
        })
      });
    };

    let statusFormat = (status: number) => {
      // console.log(status);
      return "正常";
    };

    let filterNode = (value: string, data: SysResourceNodeModel) => {
      if (!value) return true;
      return data?.name?.indexOf(value) !== -1;
    };

    let deleteRow = async (id: number) => {
      let result = (await apiSysResource.remove([id])) as RespBody<number>;
      if (result.object > 0) {
        ElMessage({
          type: "success",
          message: "刪除成功！"
        });
        await loadTreeData();
      } else
        ElMessage({
          type: "error",
          message: "刪除失敗"
        });
    };

    watch(filterText, (n) => {
      (leftTree.value as any).filter(n);
    });

    onMounted(() => {
      loadTreeData();
      bus.on("on-table-row-edit-ok", () => {
        loadTreeData();
      });
    });

    let sortUpDown = async (id: number, type: string) => {
      let ids = setNewOrders(
        levelDataList.value.map((item) => item.id) as Array<number>,
        id,
        type
      );

      if (ids) {
        let result = (await apiOther.sortIndex(
          "sys_resource",
          ids
        )) as RespBody<number>;

        if (result.object > 0) {
          ElMessage.success("排序操作成功");
          levelDataList.value = putOrders(levelDataList.value, ids);
        }
      } else {
        ElMessage.warning("无法操作排序");
      }
    };

    return {
      leftTree,
      sysResourceView,
      pageParams,
      filter,
      filterText,
      levelDataList,
      leftTreeData,
      defaultProps,
      activeTabName,
      currentNodeModel,
      statusFormat,
      filterNode,
      onNodeClick,
      clickEditCurrent,
      clickCreate,
      deleteRow,
      sortUpDown
    };
  }
});
